import { TriggerType } from '../types/job';

export type SchedulePeriod =
  | 'hourly'
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'unclear';

export type SelectedSchedule = {
  trigger: TriggerType;
  trigTimed?: {
    period: SchedulePeriod;
    minuteOfHour?: number;
    hourOfDay?: number;
    dayOfWeek?: number;
    dayOfMonth?: number;
  };
  trigDelayed?: string;
  trigCron?: string;
};

export const quartzCronToTrigTimed = (
  quartzString: string | undefined
): SelectedSchedule['trigTimed'] => {
  if (!quartzString) return undefined;
  const parts = quartzString.split(' ').slice(0, 6);

  const minuteOfHour = parts[1] === '*' ? undefined : Number(parts[1]);
  const hourOfDay = parts[2] === '*' ? undefined : Number(parts[2]);
  const dayOfMonth = ['*', '?'].includes(parts[3])
    ? undefined
    : Number(parts[3]);
  const dayOfWeek = ['*', '?'].includes(parts[5])
    ? undefined
    : Number(parts[5]) - 1;

  let period: SchedulePeriod;

  if (
    minuteOfHour !== undefined &&
    hourOfDay === undefined &&
    dayOfMonth === undefined &&
    dayOfWeek === undefined
  ) {
    period = 'hourly';
  } else if (
    minuteOfHour !== undefined &&
    hourOfDay !== undefined &&
    dayOfMonth === undefined &&
    dayOfWeek === undefined
  ) {
    period = 'daily';
  } else if (
    minuteOfHour !== undefined &&
    hourOfDay !== undefined &&
    dayOfMonth === undefined &&
    dayOfWeek !== undefined
  ) {
    period = 'weekly';
  } else if (
    minuteOfHour !== undefined &&
    hourOfDay !== undefined &&
    dayOfMonth !== undefined &&
    dayOfWeek === undefined
  ) {
    period = 'monthly';
  } else {
    period = 'unclear';
  }

  return {
    period: period,
    hourOfDay: hourOfDay,
    minuteOfHour: minuteOfHour,
    dayOfWeek: dayOfWeek,
    dayOfMonth: dayOfMonth,
  };
};

// CronScheduleSelect component creates cron strings with 5 elements
// JobOrchestration works with quartz cron strings with 7 elements
export const cronToQuartzCron = (cronString: string) => {
  const split = cronString.split(' ');
  const dayOfTheMonth = split[2];
  const dayOfTheWeek = split[4];
  if (dayOfTheMonth !== '*') {
    split[4] = '?';
  } else if (dayOfTheWeek !== '*') {
    split[2] = '?';
  } else if (dayOfTheMonth === '*' && dayOfTheWeek === '*') {
    split[2] = '?';
  }
  split.splice(0, 0, '0');
  split.splice(6, 0, '*');
  return split.join(' ');
};

export const quartzCronToCron = (cronString: string): string | undefined => {
  if (!cronString) return undefined;
  const fiveElementCron = cronString
    .replace('?', '*')
    .split(' ')
    .slice(1, 6)
    .join(' ');
  return fiveElementCron;
};

/**
 * Takes a schedule object and converts it to a Quartz Cron String
 * @param schedule
 */
export const trigTimedToQuartzCron = (
  trigTimed: SelectedSchedule['trigTimed']
): string | undefined => {
  if (!trigTimed) return undefined;

  const { period, dayOfWeek, dayOfMonth, minuteOfHour, hourOfDay } = trigTimed;

  if (period === 'hourly') {
    return `0 ${minuteOfHour} * * * ? *`;
  } else if (period === 'daily') {
    return `0 ${minuteOfHour} ${hourOfDay} * * ? *`;
  } else if (period === 'weekly') {
    return `0 ${minuteOfHour} ${hourOfDay} ? * ${
      (dayOfWeek ? dayOfWeek : 0) + 1
    } *`;
  } else if (period === 'monthly') {
    return `0 ${minuteOfHour} ${hourOfDay} ${
      dayOfMonth ? dayOfMonth : 1
    } * ? *`;
  }
};
