import msgsForm from 'common/dist/messages/form';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import qs from 'qs';
import React, { FC, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { validatePath } from './cloneRepository.form';
import { useContent } from '../../../../core/api/workbench/content';
import { defaultSidebarSize } from '../../../../core/constants/layout';
import { DeprecatedRootState } from '../../../../store/state.type';
import { JupyterContentElement } from '../../../../store/workbench/state.types';
import { err, ok, Result } from '../../../../utils';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import Busy from '../../../atoms/busy/Busy';
import { ButtonProps } from '../../../atoms/button/Button';
import BubbleStep from '../../../molecules/bubble-step/BubbleStep'; /*Create the bubble-step component to use it in this component. The old step still exists in the NewModuleWizard*/
import {
  contextMenuEntries,
  onClickListeners,
} from '../../../organisms/workbench-browser/clone-repository-file-browser/CloneRepositoryFileBrowser.defaults';
import GenericFileBrowser, {
  ContentElement,
} from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser';
import { prepareContent } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser.utils';
import { dnd } from '../../../organisms/workbench-browser/recycle-bin-file-browser/RecycleBinFileBrowser.defaults';
import { icons } from '../../../organisms/workbench-browser/workbench-file-browser/WorkbenchFileBrowser.defaults';
import Wizard from '../../../pages/wizard/Wizard';
import { useSelectedDirPath } from '../../../workbench/hooks';
import { moduleDetailsLink, repositoryDetailsLink2 } from '../../routes';

type Props = {
  fetchRepoDetails: ToBeRefined;
  filledCompletely: boolean;
  cloneGitRepository: ToBeRefined;
  /** Code of the repository */
  repoCode: string;
  /** Derived from the first and last name of the currently logged in user */
  gitDisplayName: string;
  gitMail: string;
  activeBranch: string;
  repoDetails: {
    data: {
      repoName: string;
      repoFullName: string;
      name: string;
      repoType: string;
      codeCapsuleCode: string;
      appCode: string;
    };
    loading: boolean;
  };
  checkWhetherNotebookIsRunning: ToBeRefined;
  notebookRunning: {
    loading: boolean;
    loaded: boolean;
    isRunning: boolean;
    error: string;
  };
  notebookUser: ToBeRefined;
};

const CloneRepositoryWizard: FC<Props> = ({
  filledCompletely,
  cloneGitRepository,
  gitDisplayName,
  gitMail,
  repoCode,
  activeBranch,
  checkWhetherNotebookIsRunning,
  notebookUser,
  fetchRepoDetails,
  repoDetails,
  notebookRunning: { loading, isRunning },
}) => {
  const history = useHistory();
  const methods = useForm({
    mode: 'all',
  });

  const {
    formState: { isDirty, isValid, isSubmitSuccessful, isSubmitting },
    control,
    handleSubmit,
    trigger,
  } = methods;
  const { group, repositoryName } = useRouteMatch<{
    group: string;
    repositoryName: string;
  }>().params;

  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const moduleCode = queryParams?.moduleCode as string | undefined;
  const selectedDirPath = useSelectedDirPath();
  const width =
    useSelector<DeprecatedRootState, number>(
      (state) => state.workbench.browserWidth
    ) || defaultSidebarSize;

  const { data, error, isError } = useContent(
    contentArrayToPath(selectedDirPath, false)
  );
  const content = (data?.content ?? []) as JupyterContentElement[];

  const selectedContent: Result<ContentElement[], any> = isError
    ? err(error)
    : ok(prepareContent(content, selectedDirPath));

  useEffect(() => {
    checkWhetherNotebookIsRunning(notebookUser, false);
  }, [checkWhetherNotebookIsRunning, notebookUser]);

  useEffect(() => {
    fetchRepoDetails(group, repositoryName);
  }, [fetchRepoDetails, group, repositoryName]);
  useEffect(() => {
    trigger('path');
  }, [selectedDirPath, repoDetails, selectedContent]);
  const selectedDirPathString = contentArrayToPath(useSelectedDirPath());

  if (isSubmitSuccessful) history.push(workbenchRoutes.basePath);

  function renderLoading() {
    return (
      <div className={'CollaborationSpace--content'}>
        <Busy isBusy />
      </div>
    );
  }

  function renderLoaded() {
    const { repoName, repoFullName, repoType, codeCapsuleCode, appCode, name } =
      repoDetails?.data || {};

    // Content that will be written into the repository.asr meta file of the cloned repository (locally in the private
    //  notebook space - not on the git server)
    const metaFileContent = JSON.stringify({
      repoCode,
      repoType,
      repoName,
      name,
      codeCapsuleCode,
      appCode,
      // Includes the group (=organization) as prefix to the repoName
      // TODO may be better to add owner.username, to avoid string manipulation. Would need to come from backend and be added while creating the repository
      //  although that is only useful to distinguish the gitea group (=organization = owner of a repo)
      repoFullName,
    });

    // repoName can be missing if only the git infos are available, but we still need a dir to clone into and its only(?!) used for that
    const repoNameSafe =
      repoName !== undefined ? repoName : repoFullName?.split('/')[1];
    const cancelButtonLink = moduleCode
      ? moduleDetailsLink(moduleCode) // Cloning a Module
      : repositoryDetailsLink2(group, repositoryName); // Cloning an App, Code Capsule or Plain Repository

    const onSubmit = () => {
      if (filledCompletely && isRunning) {
        cloneGitRepository(
          selectedDirPathString,
          repoNameSafe,
          repoFullName,
          gitDisplayName,
          gitMail,
          metaFileContent,
          activeBranch
        );
      }
    };

    if (!isRunning) {
      return (
        <div className={'dialog-container clone-repository-wizard'}>
          <div className={'dialog-inner-container'}>
            <p className={'dialog-headline'}>Clone Repository</p>
            <div className={'start-workbench-parent dialog-button-container'}>
              <p>
                Your Workbench isn't running yet. Please start it first before
                you can clone your repository.
              </p>
              <div className={'buttons'}>
                <Link to={cancelButtonLink}>
                  <button
                    type={'button'}
                    className={'dialog-button dialog-button-neutral'}
                  >
                    Cancel
                  </button>
                </Link>
                <Link to={workbenchRoutes.basePath}>
                  <button
                    type={'button'}
                    className={'dialog-button dialog-button-ok'}
                  >
                    Go to Workbench
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
    /*Use the old buttonprops for the patch*/
    const buttons: ButtonProps[] = [
      {
        buttonColor: 'white',
        label: msgsForm.cancel,
        withLink: true,
        linkTo: cancelButtonLink,
      },
      {
        buttonColor: 'secondary',
        isSubmitButton: true,
        label: msgsForm.submit,
        disabled: !isValid,
        isBusy: isSubmitting,
        withLink: false,
      },
    ];
    return (
      <div className={'dialog-container clone-repository-wizard'}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Wizard
              /*The wizard is still using the old props before refactoring*/
              withHeadline={true}
              headlineDefault={'Clone Repository'}
              buttons={buttons}
            >
              <div style={{ height: '100%' }}>
                <Controller
                  name={'path'}
                  control={control}
                  rules={{
                    validate: () =>
                      validatePath(selectedDirPath, methods.getValues(), {
                        repoName: repoDetails?.data?.repoName,
                        notebooksInCurrentDir: selectedContent ?? [],
                      }),
                  }}
                  render={({ field, fieldState }) => {
                    const { ref, ...rest } = field; // extract ref to pass as inputRef
                    return (
                      <BubbleStep
                        title={'Select Path'}
                        description={
                          'Select a path to clone the repository into.'
                        }
                        /*Old prop before refactoring*/
                        number={1}
                        isErroneous={!!fieldState.error}
                        isValid={!fieldState.invalid}
                      >
                        {fieldState.error && (
                          <small
                            className='InputError'
                            title={fieldState.error.message}
                          >
                            <span>{fieldState.error.message}</span>
                          </small>
                        )}
                        <GenericFileBrowser
                          content={selectedContent}
                          onClickListeners={onClickListeners}
                          contextMenuEntries={contextMenuEntries}
                          dnd={dnd}
                          icons={icons}
                          width={width}
                          path={selectedDirPath}
                          hideRecycleBin
                        />
                      </BubbleStep>
                    );
                  }}
                />
              </div>
            </Wizard>
          </form>
        </FormProvider>
      </div>
    );
  }

  const { loading: loadingDetails } = repoDetails || {};
  if (loading || loadingDetails) return renderLoading();
  else return renderLoaded();
};

export default CloneRepositoryWizard;
