import { QueryKey } from '@tanstack/react-query';
import { isApiError } from 'common/dist/constants/errors';
import { ApiError } from 'common/dist/types/responseBodies/errors';

import keycloak, { updateToken } from '../../../keycloak';

export const IS_API = '@@API';

export const HEADERS = {
  JSON: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export function isApiCallResult(result) {
  return !!(result && result[IS_API]);
}

/**
 * TODO does not quite work as expected. The goal is an Either type so that you can extract both
 *  response and error: `const { response, error } = ...` and typescript will know which it is after checking `if (response) ...`
 */
export type CompletedApiRequest<T = unknown> =
  | Promise<SuccessApiRequest<T>>
  | Promise<ErrorApiRequest>;

export type SuccessApiRequest<T = unknown> = {
  response: T;
  error?: never;
  status: number;
};

export type ErrorApiRequest = {
  response?: never;
  error: ApiError;
  [IS_API]: boolean;
};

export type Options = {
  headers?: Record<string, string>;
  [option: string]: unknown;
};

// YYYY-MM-DDTHH:mm:ss.sssZ https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
const IsoSimpRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/;
function parseDatesReviver(key: any, value: any) {
  if (!(typeof value == 'string' && IsoSimpRegex.exec(value))) {
    return value;
  }
  const date = new Date(value);
  if (!isFinite(Number(date))) {
    console.error(
      `Unexpected error when trying to convert key ${key} with value ${value} into a Date`
    );
    return value;
  }
  return date;
}

const MAX_RETRIES = 3;

export async function apiRequest<T>(
  url: string,
  options?: Options,
  retry = true
  // @ts-ignore Wait for tsconfig target > es2015 and rewrite it like this type CompletedApiRequest<T = unknown> = Promise< SuccessApiRequest<T> | ErrorApiRequest >;
): CompletedApiRequest<T> {
  const passedHeaders = options ? options.headers : {};
  const optionsNoCache: Options = {
    ...options,
    headers: {
      ...passedHeaders,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
  };
  try {
    const refreshed = await updateToken();
    optionsNoCache.headers = {
      ...optionsNoCache.headers,
      Authorization: `Bearer ${keycloak.token}`,
    };

    let response: Response;
    let retries = 0;
    while (!response) {
      try {
        response = await fetch(
          url,
          // @ts-ignore mismatch between our Options type and RequestInit (import from where?)
          Object.assign({}, optionsNoCache, { credentials: 'include' })
        );
      } catch (e) {
        if (!retry || retries >= MAX_RETRIES) throw e;
        retries = retries + 1;
      }
    }

    if (response.ok) {
      try {
        const raw = await response.text();
        const data = JSON.parse(raw, parseDatesReviver);
        return { response: data, status: response.status };
      } catch (err) {
        console.log(err);
        // @ts-ignore
        return { response: {}, status: response.status }; // If the json can't be parsed, return an empty object for the response
      }
    }

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      try {
        const json = await response.json();
        return {
          [IS_API]: true,
          error: json,
          status: response.status,
        };
      } catch (err) {
        return {
          [IS_API]: true,
          error: err.message,
          status: response.status,
        };
      }
    }
    const text = await response.text();
    return {
      [IS_API]: true,
      // @ts-ignore TODO this should not be possible, since the backend api always returns json
      error: text,
      status: response.status,
    };
  } catch (e) {
    console.error('e: ', e);
    // TODO: by returning here this is technically no longer a 'CompletedApiRequest' because it failed in another way
    //   However, returning sth here is currently necessary because otherwise the Sagas (and also React Query) will crash
    //   With the removal of Sagas this might be easy to handle in the fetchQueryFn function and this return can be removed again
    return {
      [IS_API]: true,
      // the ? is probably unnecessary but this ensures there is no e is undefined error
      // this is also not the correct format, this should be a FormattedMessage, but we have fallback handling for that which suffices for now
      error: e?.message,
      status: 418, // No status code will fit this, so might as well use the teapot status ¯\_(ツ)_/¯
    };
  }
}

export function putApiRequest<T>(url, body, options = { headers: {} }) {
  return apiRequest<T>(
    url,
    {
      ...options,
      method: 'PUT',
      headers: {
        ...options.headers,
        ...HEADERS.JSON,
      },
      body: JSON.stringify(body),
    },
    false
  );
}

export function postApiRequest<T>(url, body = {}, options = { headers: {} }) {
  return apiRequest<T>(
    url,
    {
      ...options,
      method: 'POST',
      headers: {
        ...options.headers,
        ...HEADERS.JSON,
      },
      body: JSON.stringify(body),
    },
    false
  );
}

export function postUploadRequest<T>(url, files) {
  const data = new FormData();
  data.append('files', files);

  return apiRequest<T>(
    url,
    {
      method: 'POST',
      body: data,
    },
    false
  );
}

export function deleteApiRequest<T>(url, options = { headers: {} }) {
  return apiRequest<T>(
    url,
    {
      ...options,
      method: 'DELETE',
      headers: options.headers,
    },
    false
  );
}

/**
 * Wrapper function for the React Query queryFn functions.
 * Extracts the response if the request was successful or otherwise throws an error
 *    to conform to React Query's 'throw on error'-pattern.
 */
export const fetchQueryFn = async <
  TQueryFnData = unknown,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  fetchFn: () => CompletedApiRequest<TQueryFnData>
): Promise<TQueryFnData> => {
  // TODO: it is possible that the fetch function fails and there is no CompletedApiRequest
  const { response, error } = await fetchFn();
  if (!response) {
    // Handle our ApiErrors, so that we keep them intact to extract the formattedMessage later
    // TODO we could also build a new error and pass the ApiError as the cause
    if (isApiError(error)) {
      console.error(
        `Unable to fetch [ ${queryKey} ]: ${JSON.stringify(error)}.`
      );
      throw error;
    } else {
      throw Error(`Unable to fetch [ ${queryKey} ]: ${JSON.stringify(error)}.`);
    }
  }
  return response;
};
